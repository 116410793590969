import { useContext, useState, useEffect } from "react";
import $ from "jquery";
import { DataContext } from "../../../../../../context/dataContext";
import moment from "moment";
import { UserContext } from "../../../../../../context/userContext";
import { API } from "../../../../../../http-common";
/*Edit modules imports*/
import EditModule from "../EditModules";
import EditFeedback from "../EditFeedback";
import { Spinner } from "react-bootstrap";
const Accordion7 = ({ idSearch, module, applicant, disableEdit, onDataChange = null }) => {
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [dataModule, setDataModule] = useState(module);
  const [submit, setSubmit] = useState(false);
  const { userData, setUserData } = useContext(UserContext);
  const [dateStart, setDateStart] = useState(
    dataModule.data.date !== ""
      ? dataModule.data.date
      : moment(new Date()).format("YYYY-MM-DD")
  );
  const [moduleStatus, setModuleStatus] = useState(module.status);
  const [feedbackError, setFeedbackError] = useState(false);

  /**textarea for feedback state*/
  const [feedback, setFeedback] = useState(dataModule.data.feedback);

  /*EditModal states & function*/
  const [moduleWritable, setModuleWritable] = useState(module.writable);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const editionMode = () => {
    setModuleWritable(1);
    setShowModal(false);
  };

  const isModal = document.getElementsByClassName("modal_edit_applicant");



  const toggleClass = () => {
    let modal = document.getElementsByClassName("modal_edit_applicant");
    if(modal.length > 0){
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }
  }

  useEffect(() => {
    if (submit && !feedbackError) {
      sendData();
    }
    // getCandidate();
    setSubmit(false);
  }, [submit]);

  const sendData = () => {
    setIsLoading(true)
    const hoy = moment(new Date()).format("YYYY-MM-DD");
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 7,
      employees_id: userData.id,
      feedback_date: hoy,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: dateStart,
      status: moduleStatus,
    };
    const module_id = dataModule.id ?? 0
    
    try{
      API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
      .then((response) => {
        //setDataTableChanged(true);
        setModuleWritable(0);
        setDataModule({
          ...dataModule,
          id: response.data.id
        })
        if(null !== onDataChange) onDataChange()
        toggleClass()
        setIsLoading(false)
      })
    }
    catch(error){
      console.log(error)
      setIsLoading(false)
    }
      
  };

  const handleInterview7 = (rejection) => {
    setModuleStatus(1);
    setSubmit(true);
    checkInputs(rejection);
  };

  const checkInputs = (rejection) => {
    if (!rejection) {
      if (dateStart.length === 0) {
        setFeedbackError(true);
      } else {
        setFeedbackError(false);
      }
    } else {
      setModuleStatus(2);
      setFeedbackError(false);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    //const date = moment(new Date).format('YYYY-MM-DD')
    const date = moment(value).format("YYYY-MM-DD");
    setDateStart(value);
  };

  return (
    <>
      <div className="card">
        <div className="card-header" id="ingreso-heading">
          <h5 className="mb-0">
            <button
              className="btn btn-link"
              data-toggle="collapse"
              data-target="#ingreso"
              aria-expanded="false"
              aria-controls="ingreso"
            >
              Ingreso
            </button>
            <span
              className="mas"
              data-toggle="collapse"
              data-target="#ingreso"
              aria-expanded="false"
              aria-controls="ingreso"
            >
              +
            </span>
          </h5>
        </div>
        <div
          id="ingreso"
          className={
            moduleWritable === 1
              ? "collapse show"
              : "collapse show disabled"
          }
          aria-labelledby="ingreso-heading"
        >
          <div className="card-body">
            <div className="barra-superior">
              <div className="card-body">
                <div className="left">
                  <div className="item">
                    <span>Fecha de ingreso</span>

                    {disableEdit || moduleWritable === 0 ? (
                      (module.completed === 1) & (moduleStatus === 1) ? (
                        <span className="fecha module">
                          {moment(dateStart, "YYYY-MM-DD").format("DD/MM/YYYY")}
                        </span>
                      ) : (module.completed === 1) & (moduleStatus === 2) ? (
                        <span className="fecha module">NO INGRESÓ</span>
                      ) : (
                        <input type="date" disabled value={dateStart} />
                      )
                    ) : (
                      <input
                        type="date"
                        onChange={handleOnChange}
                        value={dateStart}
                      />
                    )}
                  </div>
                </div>
                {
                  <div className="content-feedback">
                    <span>Feedback</span>
                    <textarea
                      id="feedback-medica"
                      cols="30"
                      rows="10"
                      placeholder=""
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                      readOnly={moduleWritable === 0 ? true : false}
                      disabled={false}
                    ></textarea>
                    <span className={feedbackError ? "span-error" : "d-none"}>
                      Campo Vacio
                    </span>
                  </div>
                }
              </div>
            </div>

            {moduleWritable === 0 && moduleStatus === 2 && (
              <div className="botones-vista">
                <button
                  id="negar"
                  disabled
                  onClick={() => $("#no-avanza-busqueda-7").modal("show")}
                  data-toggle="modal"
                  data-target="#no-avanza-busqueda-7"
                >
                  NO INGRESA
                </button>
                {dataModule.completed === 1 && module.editable === "feedback" && (
                  <EditFeedback
                    setModuleWritable={setModuleWritable}
                    sendData={sendData}
                    feedbackError={feedbackError}
                  ></EditFeedback>
                )}
                {dataModule.completed === 1 && module.editable === "all"&& (
                  <EditModule
                    editionMode={editionMode}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  ></EditModule>
                )}
              </div>
            )}

            {moduleWritable === 0 && moduleStatus === 1 && (
              <div className="botones-vista">
                <button
                  id="aceptar"
                  disabled
                  onClick={() => $("#avanza-busqueda-7").modal("show")}
                  data-toggle="modal"
                  data-target="#avanza-busqueda-7"
                >
                  INGRESO CONFIRMADO
                </button>
              </div>
            )}


            {moduleWritable === 1 && (
              <div className="botones-vista">
                <button
                  id="aceptar"
                  onClick={() => $("#avanza-busqueda-7").modal("show")}
                  data-toggle="modal"
                  data-target="#avanza-busqueda-7"
                  disabled={isLoading ? true : false}
                >
                  CONFIRMAR INGRESO
                </button>

                <button
                  id="negar"
                  onClick={() => $("#no-avanza-busqueda-7").modal("show")}
                  data-toggle="modal"
                  data-target="#no-avanza-busqueda-7"
                  disabled={isLoading ? true : false}
                >
                  NO INGRESA
                </button>
              </div>
            )}

            {dataModule.completed === 1 && module.editable === "all"&& (
              <EditModule
                editionMode={editionMode}
                showModal={showModal}
                setShowModal={setShowModal}
              ></EditModule>
            )}

           
          </div>
        </div>
      </div>

      {/* Modal de Ingreso */}
      <div
        className="modal fade"
        id="avanza-busqueda-7"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
              {
                !isLoading && (
                  <>
                <div className="modal-body ">
              <span className="texto-general">
                Se guardará la fecha seleccionada como fecha de ingreso ¿Estas
                seguro de proseguir?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={() => handleInterview7()}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
                  </>
                )
              }
            {
              isLoading && (
                <div className="spinner">
                <Spinner 
                animation="border" 
                variant="primary"
                style={{
                  width: '6rem',
                  height: '6rem',
                  margin: 'auto',
                  display: 'block'
                }}
                />
                </div>
              )
            }
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="no-avanza-busqueda-7"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                El candidato NO INGRESARA ¿Estas seguro de proseguir?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={() => handleInterview7(true)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion7;
